import axios from 'axios';
import qs from 'qs';

const state = () => ({
    register_url: localStorage.getItem('register_url') || '',
})

const mutations = {
    /**
     * Set user_info
     * @param {*} state 
     * @param {*} data 
     */
    setRegisterUrl(state, data) {
        state.register_url = data
    },

    /**
     * DetroyAuth state info
     * @param {*} state 
     */
    destroyRegisterUrl(state) {
        state.register_url = ''
    },
}

const getters = {

    /**
     * Get access token
     * @param {*} state 
     * @returns 
     */
    getRegisterUrl(state) {
        return state.register_url
    }
}

const actions = {
    rentRoom(context, credentials) {
        return new Promise((resolve, reject) => {
            var [first_name, ...last_name] = credentials.full_name.split(' ');
            last_name = last_name.join(' ');
            console.log(last_name);
            if (last_name == '') {
                var error = 'no last name'
                reject(error)

            } else {
            axios.post('https://api.dev.letmein.asia/web/v3/rental/rentroom', {
                first_name: first_name,
                last_name: last_name,
                guest_amount: credentials.guest_amount,
                rent_start_time: credentials.rent_start_time,
                rent_end_time: credentials.rent_end_time,
                language: 'en',
                //license_plate: ''
            })
                .then((response) => {
                    console.log(response.status);
                    console.log(response.data);
                    if (response.status == 200) {
                        console.log(response.data);
                        var data = response.data
                        // Set to local storage
                        localStorage.setItem('register_url', data.register_url)

                        // Set to state
                        context.commit('setRegisterUrl', data.register_url)
                        resolve(data)
                    } else {
                        console.error(response);
                        reject(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)

                });
            }

        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}