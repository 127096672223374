<template>
  <div id="Login">
    <img alt="logo" src="../assets/logo.png" />
    <h1></h1>
    <form @submit.prevent="clickLogin()">
      <div class="row" v-for="element in inputElements" :key="element.id">
        <div class="">
          <inputElements
            :classname="element.classname"
            :id="element.id"
            :login="true"
            :type="element.type"
            :placeholder="element.label"
            v-model="element.value"
          />
        </div>
      </div>
      <!-- Error message -->
      <div class="row" v-show="isError">
        <p style="color: red; font-size: 0.875rem; text-align: left">
          {{ errorMessage }}
        </p>
      </div>
      <button class="button">Sign in</button>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import inputElements from "@/components/Input.vue";

export default {
  name: "Login",
  components: {
    inputElements,
  },
  data() {
    return {
      isError: false,
      errorMessage: null,
      inputElements: [
        {
          id: "username",
          type: "text",
          placeholder: "E-mail",
          label: "E-mail",
          value: "",
          classname: "username",
        },
        {
          id: "password",
          type: "password",
          placeholder: "Password",
          label: "Password",
          value: "",
          classname: "pass",
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("login/destroyAuth");
  },

  methods: {
    /**
     * Click submit for login
     */
    clickLogin: function () {
      var isValid = this.validationInput();
      console.log("validation ::", isValid);
      if (isValid) {
        this.$store
          .dispatch("login/retrieveAuth", {
            username: this.inputElements[0].value,
            password: this.inputElements[1].value,
          })
          .then((response) => {
            console.log("API Login ::", response);
            console.log(response.data.status);
            if (response.data.status == "success") {
              this.isError = false;
              this.$router.push({ name: "GetUrl" });
            } else {
              this.isError = true;
              this.errorMessage = "Username and Password No information found";
            }
          })
          .catch((error) => {
            console.log(error);
            this.isError = true;
            this.errorMessage = "Username or Password Incorrect";
          });
      }
    },

    /**
     * Validation function input
     * @params username
     * @params password
     * @return ture, false
     */
    validationInput: function () {
      if (
        this.inputElements[0].value !== "" &&
        this.inputElements[1].value !== ""
      ) {
        return true;
      } else return false;
    },
  },
  // logout() {
  //   this.$store
  //     .dispatch("login/destroyAuth")
  //     .then(console.log('test'));
  // },
};
</script>

<style scoped>
.hi {
  width: 50px;
  height: 50px;
}
#Login {
  margin-top: 25%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
