<template>
  <div>
    <Navbar></Navbar>
    <div class="title-content">
      <h2>Create QR code for renter</h2>
    </div>
    <form @submit.prevent="clickRent()">
      <div class="row" v-for="element in inputElements" :key="element.id">
        <div>
          <inputElements
            :id="element.id"
            :type="element.type"
            :label="element.label"
            v-model="element.value"
          />
        </div>
      </div>
      <div class="row" v-show="isError">
        <p style="color: red; font-size: 1.25rem; text-align: left">
          {{ errorMessage }}
        </p>
      </div>
      <button class="button">Confirm</button>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar.vue";
import inputElements from "@/components/Input.vue";

export default {
  name: "Geturl",
  components: {
    Navbar,
    inputElements,
  },
  data() {
    return {
      isError: false,
      errorMessage: null,
      inputElements: [
        {
          id: "name",
          type: "text",
          label: "First - Last name",
          value: "",
        },
        {
          id: "guests",
          type: "text",
          label: "Number of guests",
          value: "",
        },
        {
          id: "startTime",
          type: "datetime-local",
          label: "Booking start",
          value: "",
        },
        {
          id: "endTime",
          type: "datetime-local",
          label: "Booking end",
          value: "",
        },
      ],
    };
  },
  methods: {
    /**
     * Click submit for login
     */
    clickRent: function () {
      var isValid = this.validationInput();
      console.log("validation ::", isValid);
      if (isValid) {
        this.$store
          .dispatch("rent/rentRoom", {
            full_name: this.inputElements[0].value,
            guest_amount: this.inputElements[1].value,
            rent_start_time: this.inputElements[2].value,
            rent_end_time: this.inputElements[3].value,
          })
          .then((response) => {
              console.log("API Rent Room ::", response);
              this.$router.push({ name: "ShareUrl" });
            // console.log("API Rent Room ::", response);
            // this.$router.push({ name: "ShareUrl" });
          })
          .catch((error) => {
            console.log(error+ 'here+++++++++++++++++++++++++');
            if (error == 'no last name') {
              this.isError = true;
              this.errorMessage = 'Please enter your first and last name';
            } else if (error.response.status == 400) {
              console.log(error.response + "error here");
              this.isError = true;
              this.errorMessage = error.response.data.message_en;
            } else {
              this.$router.push({ name: "Login" });
            }

            
          });
      }
    },

    /**
     * Validation function input
     * @params username
     * @params password
     * @return ture, false
     */
    validationInput: function () {
      if (
        this.inputElements[0].value !== "" &&
        //this.inputElements[1].value !== "" &&
        this.inputElements[2].value !== "" &&
        this.inputElements[3].value !== ""
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style>
h2 {
  margin-top: 40px;
  color: #0063e0;
}

.title-content {
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>