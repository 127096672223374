import axios from 'axios';
import qs from 'qs';


const state = () => ({
    access_token: localStorage.getItem('access_token') || '',
})

const mutations = {
    /**
     * Set user_info
     * @param {*} state 
     * @param {*} data 
     */
    setAuthInfo(state, data) {
        state.access_token = data.token
    },

    /**
     * DetroyAuth state info
     * @param {*} state 
     */
    destroyAuth(state) {
        state.access_token = ''
    },
}

const actions = {
    /**
   * Login Function
   * @param {*} context 
   * @param {*} credentials 
   */
    retrieveAuth(context, credentials) {
        return new Promise((resolve, reject) => {
            var data = qs.stringify({
                'username': credentials.username,
                'password': credentials.password
            });
            var config = {
                method: 'post',
                url: 'https://api.dev.letmein.asia/web/v3/rental/login',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    if (response.status == 200) {
                        const authInfo = response.data
                        localStorage.setItem('access_token', authInfo.data.token)
                        context.commit('setAuthInfo', authInfo.data)
                        resolve(response)
                    } else {
                        console.log(response)
                        reject(response)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    reject(error)

                });
        })
    },
    destroyAuth(context) {
        if (context.getters.loggedIn) {
            localStorage.clear()
            context.commit('destroyAuth')
        }
    }
}
const getters = {
    /**
     * User for Navigation guard
     */
    loggedIn(state) {
        return state.access_token !== ''
    },

    /**
     * Get access token
     * @param {*} state 
     * @returns 
     */
    getAccessToken(state) {
        return state.access_token
    },

    /**
     * Get auth info 
     * @param {*} state 
     * @returns state
     */
    authInfo(state) {
        return state
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
